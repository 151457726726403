.p-component {
  font-family: inherit;
  font-size: inherit;
}

.p-dataview {
  &.p-dataview-grid {
    display: flex;
    flex-direction: column;
  }
}
