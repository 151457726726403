.p-accordion-content {
  padding: 0 !important;
}
.p-accordion-header {
  &-link {
    flex-direction: row-reverse;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none !important;
    }
  }
  .title {
    display: flex;
    flex: auto;
    margin-bottom: 0 !important;
    span {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      align-self: center;
      margin: 0px 10px;
      border: 1px solid grey;
      &.success {
        border-color: #32ad0c;
        background-color: #32ad0c;
        color: white;
        &:before {
          font-family: "primeicons";
          content: "\e909";
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    label {
      font-size: 16px;
    }
  }
  span {
    font-size: 12px;
    margin: 0px 10px;
  }
}
