.p-treetable {
  font-family: "GothamBook";
  &.inset {
    width: 60%;
    margin-left: auto;
    table {
      border-collapse: collapse !important;
      td,
      th {
        padding: 0;
        &.shading {
          background-color: $contrast-color-2 !important;
        }
      }
    }
    .p-treetable-thead > tr > th {
      padding: 0;
      font-size: smaller;
    }
    .p-treetable-tbody > tr > td {
      border: none !important;
      padding: 0 !important;
    }
  }
  &.custom-table {
    font-size: large;
    thead {
      background-color: transparent;
      th {
        font-size: small;
        font-weight: bold;
        padding: 5px 0 !important;
        background-color: transparent;
      }
    }
    tbody {
      tr {
        &.odd {
          background-color: $contrast-color-1;
        }
      }
    }
  }
}
