.p-sidebar {
  .title {
    font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 32px !important;
    padding: 5px !important;
    font-weight: 100 !important;
    border-bottom: 0px !important;
    color: #0b2d71;
    letter-spacing: 0;
    padding: 26px !important;
  }

  .subtitle {
    padding-left: 26px !important;
  }
}

.p-scrollpanel-wrapper {
  position: unset !important;
}
