.p-inputtext {
  width: 100%;
  &:disabled {
    background-color: #eee;
    opacity: 1;
    pointer-events: none;
    cursor: none;
  }
}

.p-invalid{
  color: #d45517 !important;
  font-weight: bold;
}
