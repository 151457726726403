.p-text-link{
    font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif ;
    font-size: 14px ;
    font-weight: 500;
}

.p-text-link:hover {
    color: blue; 
    text-decoration: underline; 
    cursor: pointer; 
  }

.force-center{
    margin: 0 0 0 0;
    padding: 0%;
}

.p-float-label > label {
    left: 0.5rem;
    color: #6c757d;
    font-size: 14px ;
    font-weight: 500;
    
  }

.p-float-label input:focus + label,
.p-float-label input:valid + label {
  left: 0.5rem;
  color: #6c757d;
  font-size: 14px ;
  font-weight: 500;
  margin-top: -1rem;
}

.p-float-label textarea:focus + label,
.p-float-label textarea:valid + label {
  left: 0.5rem;
  color: #6c757d;
  font-size: 14px ;
  font-weight: 500;
  margin-top: -1rem;
}

.p-float-label .p-inputwrapper-focus + label,
.p-float-label .p-inputwrapper-filled + label{
  left: 0.5rem;
  color: #6c757d;
  font-size: 14px ;
  font-weight: 500;
  margin-top: -1rem;
}

  
.p-float-label > .ng-invalid.ng-dirty + label {
    color: #f44336;
  }





