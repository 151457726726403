.indicator {
  display: block;
  margin: auto 5px;
  &.circle {
    border-radius: 50%;
    &.sm {
      height: 10px;
      width: 10px;
    }
  }
  &.lightblue {
    background-color: $lightblue;
  }
}
