@charset "UTF-8";
@import "./colors.scss";
@import "./p-component.scss";
@import "./p-dialog.scss";
@import "./p-accordion.scss";
@import "./p-multiselect.scss";
@import "./p-autocomplete.scss";
@import "./p-overlaypanel.scss";
@import "./p-dropdown.scss";
@import "./p-input-text.scss";
@import "./p-inputnumber.scss";
@import "./p-text-area.scss";
@import "./p-table.scss";
@import "./p-tree-table.scss";
@import "./p-sidebar.scss";
@import "./p-toast.scss";
@import "./p-tag.scss";
@import "./p-inputgroup.scss";
@import "./p-text.scss";
@import "./p-menubar.scss";
@import "./p-picklist.scss";
@import "./outage.scss";

//components
@import "./components/layout.scss";
@import "./components/indicators.scss";

@import "./ui-radiobutton.scss";
@import "./ui-button.scss";
@import "./ui-messages.scss";
@import "./ui-overlaypanel.scss";

body .ui-widget,
body .ui-widget .ui-widget,
body .ui-widget input,
body .ui-widget select,
body .ui-widget textarea,
body .ui-widget button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  text-decoration: none;
  font-size: 1em;
}
body .ui-widget-content {
  background-color: #ffffff;
  border: 1px solid #c7c7c7;
}
body .ui-widget-header {
  background: #ffffff;
  color: #2d353c;
  border: 1px solid #cdcdcd;
}
body .ui-state-active,
body .ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-icon {
  width: 1em;
  height: 1em;
  line-height: 1em;
}
body .ui-state-disabled,
body .ui-widget:disabled {
  opacity: 0.35;
  filter: Alpha(Opacity= 35);
  background-image: none;
  cursor: default !important;
}
body .ui-state-disabled *,
body .ui-widget:disabled * {
  cursor: default !important;
}
body .ui-corner-all {
  -moz-border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
}
body .ui-corner-top {
  -moz-border-radius-topleft: 0.25em;
  -webkit-border-top-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  -moz-border-radius-topright: 0.25em;
  -webkit-border-top-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 0.25em;
  -webkit-border-bottom-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  -webkit-border-bottom-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
body .ui-corner-left {
  -moz-border-radius-topleft: 0.25em;
  -webkit-border-top-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  -moz-border-radius-bottomleft: 0.25em;
  -webkit-border-bottom-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
body .ui-corner-right {
  -moz-border-radius-topright: 0.25em;
  -webkit-border-top-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  -webkit-border-bottom-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
body .ui-widget-overlay {
  background-color: #424242;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
body .ui-inputtext {
  background: #ffffff;
  padding: 0.5em 0.75em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -moz-border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
  font-size: 1em;
  color: #55595c;
  line-height: 1.25;
}
body .ui-inputtext.ui-state-focus,
body .ui-inputtext:focus {
  border: 1px solid #66afe9;
}

body .ui-fileupload-choose:not(.ui-state-disabled):hover,
body .ui-fileupload-choose.ui-state-focus {
  outline: 0 none;
  background-color: #0267bf;
}

body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: #025aa5;
  -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

body .ui-button.raised-btn {
  -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26),
    0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26),
    0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
}
body .ui-togglebutton.ui-button.ui-state-focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #0267bf;
}
body .ui-togglebutton:not(.ui-state-disabled).ui-state-active {
  background-color: #025aa5;
}
body .ui-chips .ui-chips-token .ui-chips-token-label {
  padding: 0.125em;
}
body .ui-chips .ui-chips-input-token input:focus {
  border: none;
}
body .ui-chkbox .ui-chkbox-box {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body
  .ui-chkbox
  .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border: 1px solid #b3b3b3;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border: 1px solid #0275d8;
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  border: 1px solid #0275d8;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

body .ui-inputswitch {
  background-color: #0275d8;
  text-transform: uppercase;
}
body .ui-inputswitch .ui-inputswitch-handle {
  background-color: #ffffff;
  border: 1px solid transparent;
  box-sizing: border-box;
}
body .ui-inputswitch .ui-inputswitch-handle.ui-state-focus {
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
body .ui-inputswitch .ui-inputswitch-on,
body .ui-inputswitch .ui-inputswitch-off {
  color: #fff;
}
body .ui-inputswitch .ui-inputswitch-on span,
body .ui-inputswitch .ui-inputswitch-off span {
  margin-right: 0.2em;
  position: relative;
  top: -0.1em;
  font-weight: 700;
}
body .ui-selectbutton.ui-buttonset .ui-button {
  background-color: #ffffff;
  border-color: #cccccc;
}
body
  .ui-selectbutton.ui-buttonset
  .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #e6e6e6;
  color: #373a3c;
}
body .ui-selectbutton.ui-buttonset .ui-button.ui-state-active {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-autocomplete .ui-autocomplete-dropdown .ui-button-text {
  padding: 0.35em;
}
body
  .ui-autocomplete.ui-autocomplete-multiple
  .ui-autocomplete-multiple-container {
  padding: 0.5em 0.75em;
}
body
  .ui-autocomplete.ui-autocomplete-multiple
  .ui-autocomplete-multiple-container
  .ui-autocomplete-input-token
  input:focus {
  border: none;
}
body .ui-autocomplete-panel {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .ui-autocomplete-panel .ui-autocomplete-items {
  padding: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  margin: 0;
  padding: 0.375em 0.75em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-group {
  padding: 0.375em 0.75em;
  background-color: #e6e6e6;
  color: #373a3c;
}
body .ui-multiselect {
  background: #ffffff;
  padding: 0.5em 0.75em;
  border: 1px solid #e6e6e6;
  -moz-border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
}
body .ui-multiselect .ui-multiselect-trigger {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
body .ui-multiselect:not(.ui-state-disabled):hover {
  color: #212121;
}
body .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger {
  background-color: #d9d9d9;
}
body .ui-multiselect .ui-multiselect-trigger .fa {
  margin-top: 0.8em;
}
body .ui-multiselect:not(.ui-state-disabled) .ui-multiselect-trigger:hover {
  background-color: #d9d9d9;
}
body .ui-multiselect .ui-multiselect-panel {
  padding: 0;
}
body .ui-multiselect .ui-multiselect-header {
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0.375em 0.75em;
}
body .ui-multiselect .ui-multiselect-header .ui-multiselect-close {
  color: #373a3c;
}
body .ui-multiselect .ui-multiselect-item {
  margin: 0;
  padding: 0.375em 0.75em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-multiselect .ui-multiselect-item:not(.ui-state-highlight):hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-multiselect .ui-multiselect-item.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-multiselect .ui-multiselect-item.ui-state-highlight .ui-chkbox {
  border: 1px solid #108ffd;
}
body .ui-multiselect .ui-multiselect-item > td {
  padding: 0.375em 0.75em;
}
body .ui-listbox {
  padding: 0;
  width: 12.5em;
}
body .ui-listbox .ui-listbox-item {
  margin: 0;
  padding: 0.375em 0.75em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-item:last-child {
  border-bottom: none;
}
body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-listbox .ui-listbox-item.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-listbox .ui-listbox-item.ui-state-highlight .ui-chkbox {
  border: 1px solid #108ffd;
}
body .ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-active):hover {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}
body .ui-listbox .ui-listbox-item > td {
  padding: 0.375em 0.75em;
}
body .ui-listbox .ui-listbox-header {
  padding: 0.375em 0.75em;
  border-top: 0 none;
  border-right: 0 none;
  border-left: 0 none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: 8em;
}
body .ui-multiselectlistbox {
  padding: 0;
}
body .ui-multiselectlistbox .ui-multiselectlistbox-header {
  padding: 0.375em 0.75em;
}
body .ui-multiselectlistbox .ui-multiselectlistbox-item {
  margin: 0;
  padding: 0.375em 0.75em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body
  .ui-multiselectlistbox
  .ui-multiselectlistbox-item:not(.ui-state-highlight):hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-multiselectlistbox .ui-multiselectlistbox-item.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-rating .ui-rating-cancel {
  display: inline-block;
}
body .ui-rating .ui-rating-cancel a {
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  display: block;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #e42a7b;
}
body .ui-rating .ui-rating-cancel a::before {
  content: "";
}
body .ui-rating .ui-rating-cancel a:hover {
  color: #e74189;
}
body .ui-rating .ui-rating-star {
  display: inline-block;
}
body .ui-rating .ui-rating-star a {
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  display: block;
  color: #373a3c;
}
body .ui-rating .ui-rating-star a::before {
  content: "";
}
body .ui-rating .ui-rating-star a:hover {
  color: #0275d8;
}
body .ui-rating .ui-rating-star-on a {
  color: #0275d8;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  display: block;
}
body .ui-rating .ui-rating-star-on a::before {
  content: "";
}
body .ui-spinner .ui-spinner-button {
  z-index: auto;
  box-sizing: border-box;
}
body .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:hover {
  background-color: #0267bf;
  color: #ffffff;
}
body .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:active {
  background-color: #025aa5;
  color: #ffffff;
}
body .ui-slider {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}
body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: -0.2em;
}
body .ui-slider.ui-slider-vertical .ui-slider-handle {
  left: -0.2em;
}
body .ui-slider .ui-slider-handle {
  background-color: #0275d8;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
body .ui-slider.ui-slider-animate .ui-slider-handle {
  -moz-transition: background-color 0.3s, left 0.3s;
  -o-transition: background-color 0.3s, left 0.3s;
  -webkit-transition: background-color 0.3s, left 0.3s;
  transition: background-color 0.3s, left 0.3s;
}
body .ui-slider .ui-slider-handle:hover {
  background-color: #0267bf;
}
body .ui-slider .ui-slider-range {
  background: #43a7fd;
}
body .ui-buttonset.ui-selectbutton .ui-button:first-child {
  border-right: none;
}
body .ui-buttonset.ui-selectbutton .ui-button:last-child {
  border-left: none;
}
body .ui-buttonset .ui-button {
  background-color: #e6e6e6;
  color: #373a3c;
  border: 1px solid transparent;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
body .ui-buttonset .ui-button:hover {
  background-color: #cdcdcd;
}
body .ui-buttonset .ui-button:focus {
  background-color: #e6e6e6;
  color: #373a3c;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #0275d8;
}
body .ui-buttonset .ui-button:active {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-buttonset .ui-button:active:focus {
  background-color: #0275d8;
  color: #ffffff;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
body .ui-buttonset .ui-button:first-child {
  -moz-border-radius-topleft: 0.25em;
  -webkit-border-top-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  -moz-border-radius-bottomleft: 0.25em;
  -webkit-border-bottom-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
body .ui-buttonset .ui-button:last-child {
  -moz-border-radius-topright: 0.25em;
  -webkit-border-top-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  -webkit-border-bottom-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
@media (max-width: 640px) {
  body .ui-buttonset .ui-button {
    margin-bottom: 0.063em;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
  }
}
body .ui-splitbutton.ui-buttonset .ui-button {
  color: #ffffff;
  background-color: #0275d8;
  border: 1px solid transparent;
}
body .ui-splitbutton.ui-buttonset .ui-button.ui-splitbutton-menubutton {
  -moz-border-radius-topright: 0.25em;
  -webkit-border-top-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  -webkit-border-bottom-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
body .ui-splitbutton.ui-buttonset .ui-button.ui-splitbutton-menubutton .fa {
  margin-left: -0.65em;
}
body .ui-splitbutton.ui-buttonset .ui-button:hover {
  background-color: #0267bf;
  color: #ffffff;
}
body .ui-splitbutton.ui-buttonset .ui-button.ui-state-focus {
  background-color: #0267bf;
  color: #ffffff;
}
body .ui-splitbutton.ui-buttonset .ui-button.ui-state-active {
  background-color: #025aa5;
  color: #ffffff;
}
body .ui-datepicker {
  padding: 0;
}
body .ui-datepicker .ui-datepicker-header {
  padding: 0.75em 0;
  background-color: #f5f5f5;
  border: 0;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  cursor: pointer;
  color: #767b7f;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 1.25em;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
  content: "";
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span {
  display: none;
}
body
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev.ui-datepicker-prev-hover {
  color: #2d353c;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  color: #767b7f;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  top: 1.25em;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before {
  content: "";
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next span {
  display: none;
}
body
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next.ui-datepicker-next-hover {
  color: #2d353c;
}
body .ui-datepicker table {
  font-size: 1em;
}
body .ui-datepicker table td a {
  min-width: 1em;
  text-align: center;
  color: #373a3c;
  font-size: 0.9em;
}
body .ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover {
  background-color: #f4f3f4;
}
body
  .ui-datepicker
  table.ui-datepicker-calendar
  td:not(.ui-state-disabled)
  a.ui-state-highlight {
  color: #373a3c;
  background-color: #e6e6e6;
}
body
  .ui-datepicker
  table.ui-datepicker-calendar
  td:not(.ui-state-disabled)
  a.ui-state-active {
  color: #ffffff;
  background-color: #0275d8;
}
body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #bcbcbc;
}
body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #bcbcbc;
}
body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #bcbcbc;
}
body .ui-organizationchart .ui-organizationchart-node-content {
  border-color: #bcbcbc;
}
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  color: #bcbcbc;
}
body .ui-trigger-calendar .ui-datepicker-trigger.ui-button {
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
body .ui-inplace .ui-inplace-display {
  padding: 0.5em 0.75em;
  -moz-border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
}
body .ui-inplace .ui-inplace-display:hover {
  background-color: #f4f3f4;
}
body .ui-fileupload .ui-fileupload-buttonbar {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
  border-bottom: 0 none;
}
body .ui-fileupload .ui-fileupload-content {
  padding: 1.25em;
}
body .ui-panel {
  padding: 0;
  border-color: rgba(0, 0, 0, 0.125);
}
body .ui-panel .ui-panel-titlebar {
  border: 0 none;
  border-bottom: 1px solid #e6e6e6;
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
  -moz-border-radius-topleft: 0.25em;
  -webkit-border-top-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  -moz-border-radius-topright: 0.25em;
  -webkit-border-top-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
  position: relative;
  color: #767b7f;
  top: 0.125em;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  color: #2d353c;
}
body .ui-panel .ui-panel-content {
  height: 100%;
  box-sizing: border-box;
  padding: 1.25em;
}
body .ui-panel .ui-panel-footer {
  padding: 0.375em 0.75em;
  border: 0 none;
  border-top: 1px solid #c7c7c7;
  margin: 0;
}
body .ui-fieldset {
  border-color: #e6e6e6;
}
body .ui-fieldset .ui-fieldset-legend {
  color: #2d353c;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler {
  display: inline-block;
  color: #767b7f;
}
body .ui-fieldset .ui-fieldset-legend:hover {
  background-color: #f4f3f4;
}
body .ui-fieldset .ui-fieldset-legend:hover .ui-fieldset-toggler {
  color: #2d353c;
}
body .ui-fieldset .ui-fieldset-legend.ui-state-focus {
  background-color: #e6e6e6;
}
body .ui-fieldset .ui-fieldset-content {
  padding: 0.375em 0.75em;
}
body .ui-notificationbar {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-accordion .ui-accordion-header {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  color: #0275d8;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

body .ui-accordion .ui-accordion-header a {
  padding: 0.75em 1.25em 0.75em 2em;
}

body
  .ui-accordion
  .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #f4f3f4;
}
body
  .ui-accordion
  .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover
  .ui-icon {
  color: #2d353c;
}
body .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-accordion .ui-accordion-header.ui-state-active a {
  color: #ffffff;
}
body .ui-accordion .ui-accordion-header.ui-tabview-outline {
  border: 0 none;
  outline: 0 none;
  background-color: #e6e6e6;
}
body .ui-accordion .ui-accordion-header.ui-tabview-outline.ui-state-active {
  background-color: #0283f1;
}
body .ui-accordion .ui-accordion-content {
  padding: 1.25em;
}
body .ui-tabview {
  padding: 0;
  border-bottom: 1px solid #c7c7c7;
}
body .ui-tabview.ui-tabview-top,
body .ui-tabview.ui-tabview-bottom,
body .ui-tabview.ui-tabview-left,
body .ui-tabview.ui-tabview-right {
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav,
body .ui-tabview.ui-tabview-left .ui-tabview-nav,
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  padding: 0;
  border: 0 none;
  background: #ffffff;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-bottom: 1px solid #cccccc;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  position: static;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-bottom: none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-default a,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-default a,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-default a,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-default a {
  padding: 0.375em 0.75em;
  color: #2d353c;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-default a:focus,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-default a:focus,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-default a:focus,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-default a:focus {
  outline: 0 none;
}
body
  .ui-tabview.ui-tabview-top
  .ui-tabview-nav
  li:not(.ui-state-active):not(.ui-state-disabled):hover,
body
  .ui-tabview.ui-tabview-bottom
  .ui-tabview-nav
  li:not(.ui-state-active):not(.ui-state-disabled):hover,
body
  .ui-tabview.ui-tabview-left
  .ui-tabview-nav
  li:not(.ui-state-active):not(.ui-state-disabled):hover,
body
  .ui-tabview.ui-tabview-right
  .ui-tabview-nav
  li:not(.ui-state-active):not(.ui-state-disabled):hover {
  border-color: #dedede;
  border-bottom: none;
  background-color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-outline,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-outline,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-outline,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-outline {
  outline: 0 none;
  background-color: #e6e6e6;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #0275d8;
  border-color: #0275d8;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #ffffff;
}
body
  .ui-tabview.ui-tabview-top
  .ui-tabview-nav
  li.ui-state-active.ui-tabview-outline,
body
  .ui-tabview.ui-tabview-bottom
  .ui-tabview-nav
  li.ui-state-active.ui-tabview-outline,
body
  .ui-tabview.ui-tabview-left
  .ui-tabview-nav
  li.ui-state-active.ui-tabview-outline,
body
  .ui-tabview.ui-tabview-right
  .ui-tabview-nav
  li.ui-state-active.ui-tabview-outline {
  background-color: #0283f1;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn {
  background-color: #ffffff;
}
body
  .ui-tabview.ui-tabview-top
  .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover,
body
  .ui-tabview.ui-tabview-bottom
  .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover,
body
  .ui-tabview.ui-tabview-left
  .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover,
body
  .ui-tabview.ui-tabview-right
  .ui-tabview-navscroller-btn:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #f4f3f4;
}
body
  .ui-tabview.ui-tabview-top
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left,
body
  .ui-tabview.ui-tabview-bottom
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left,
body
  .ui-tabview.ui-tabview-left
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left,
body
  .ui-tabview.ui-tabview-right
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-left {
  left: 0;
  height: 2.063em;
}
body
  .ui-tabview.ui-tabview-top
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right,
body
  .ui-tabview.ui-tabview-bottom
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right,
body
  .ui-tabview.ui-tabview-left
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right,
body
  .ui-tabview.ui-tabview-right
  .ui-tabview-navscroller-btn.ui-tabview-navscroller-btn-right {
  right: 0;
  height: 2.125em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-navscroller-btn .ui-icon,
body .ui-tabview.ui-tabview-bottom .ui-tabview-navscroller-btn .ui-icon,
body .ui-tabview.ui-tabview-left .ui-tabview-navscroller-btn .ui-icon,
body .ui-tabview.ui-tabview-right .ui-tabview-navscroller-btn .ui-icon {
  margin-top: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  margin-right: 0.125em;
  -moz-border-radius-topleft: 0.25em;
  -webkit-border-top-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  -moz-border-radius-topright: 0.25em;
  -webkit-border-top-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav,
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  border-bottom: none;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: 1px solid transparent;
  width: 100%;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li:hover,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li:hover {
  border: 1px solid #dedede;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  border-color: #0275d8;
  border-bottom: none;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  border-bottom: none;
  border-top: 1px solid #cccccc;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 0.125em;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:hover {
  border-bottom: 1px solid #cccccc;
  border-top-color: transparent;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  border-right: 1px solid #cccccc;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li:hover {
  border-right: none;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  border-left: 1px solid #cccccc;
  height: 9.375em;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li:hover {
  border-left-color: transparent;
}
body .ui-tabview .ui-tabview-panel {
  padding: 0.375em 0.75em;
}
body .ui-scrollpanel .ui-scrollpanel-drag {
  background-color: #e3e3e3;
}
body .ui-scrollpanel .ui-scrollpanel-track {
  background-color: #f7f7f7;
  border: 0 none;
}
body .ui-scrollpanel .ui-scrollpanel-corner {
  background-color: #f7f7f7;
}
body .ui-toolbar {
  padding: 0.375em 0.75em;
}
body .ui-toolbar .fa-ellipsis-v {
  color: #aaaaaa;
  margin: 0 0.5em;
}
body .ui-tooltip .ui-tooltip-arrow {
  color: #2d353c;
}
body .ui-tooltip .ui-tooltip-text {
  background-color: #2d353c;
}
@media (max-width: 640px) {
  body .ui-panelgrid .ui-grid-responsive .ui-grid-row {
    border: 0 none;
  }
}
body .ui-paginator {
  background-color: #ffffff;
  padding: 0;
  box-sizing: border-box;
}
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: #0275d8;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  min-height: 0.875em;
  min-width: 2em;
  padding: 0.5em 0.5em;
  vertical-align: top;
}
body .ui-paginator .ui-paginator-first:hover,
body .ui-paginator .ui-paginator-prev:hover,
body .ui-paginator .ui-paginator-next:hover,
body .ui-paginator .ui-paginator-last:hover {
  background-color: #f4f3f4;
}
body .ui-paginator .ui-paginator-first::before,
body .ui-paginator .ui-paginator-prev::before,
body .ui-paginator .ui-paginator-next::before,
body .ui-paginator .ui-paginator-last::before {
  position: relative;
  top: 0.063em;
}
body .ui-paginator .ui-paginator-first {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-prev {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-next {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-last {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-pages {
  padding: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  padding: 0.5em 0.625em;
  min-width: 2em;
  color: #0275d8;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  color: #ffffff;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:hover {
  background-color: #f4f3f4;
}
body
  .ui-paginator
  .ui-paginator-pages
  .ui-paginator-page:hover.ui-state-active {
  background-color: #0275d8;
}
body .ui-datatable .ui-datatable-header,
body .ui-datatable .ui-datatable-footer {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
}
body .ui-datatable .ui-editable-column.ui-cell-editing .ui-cell-editor {
  font-size: medium;
}
body .ui-datatable .ui-paginator {
  padding: 0;
}
body .ui-datatable .ui-datatable-thead > tr > th,
body .ui-datatable .ui-datatable-tfoot > tr > td {
  background-color: #ffffff;
  padding: 0.75em;
  border-color: #c7c7c7;
  color: #373a3c;
}
body .ui-datatable thead th.ui-sortable-column:not(.ui-state-active):hover {
  background-color: #e3e3e3;
}
body .ui-datatable thead th.ui-state-active {
  background-color: #0275d8;
  border-color: #0267bf;
  color: #ffffff;
}
body .ui-datatable thead th.ui-state-active .ui-dropdown-item {
  color: #373a3c;
}
body .ui-datatable thead th:focus {
  outline: 0 none;
  background-color: #f0f3f5;
}
body .ui-datatable thead th:focus.ui-state-active {
  background-color: #0283f1;
}
body .ui-datatable tfoot th.ui-state-default {
  background-color: #ffffff;
  padding: 0.375em 0.75em;
  border-color: #c7c7c7;
  color: #373a3c;
}
body .ui-datatable .ui-datatable-data tr.ui-datatable-even {
  background-color: #f7f7f7;
}
body .ui-datatable .ui-datatable-data tr.ui-datatable-even.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
  border-color: #0267bf;
}
body .ui-datatable .ui-datatable-data tr.ui-state-highlight {
  border-color: #0267bf;
}
body
  .ui-datatable
  .ui-datatable-data.ui-datatable-hoverable-rows
  > tr.ui-widget-content:not(.ui-state-highlight):hover {
  background-color: #e3e3e3;
  cursor: pointer;
}
body .ui-datatable .ui-datatable-data tr.ui-row-editing.ui-datatable-odd,
body .ui-datatable .ui-datatable-data tr.ui-row-editing.ui-datatable-even {
  border-color: #c7c7c7;
  border-bottom-color: #0275d8;
}
body .ui-datatable .ui-datatable-data > tr > td {
  padding: 0.75em;
}
body .ui-datatable .ui-datatable-data tr td.ui-editable-column input {
  width: 100%;
  box-sizing: border-box;
  border: 0 none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-datatable .ui-datatable-scrollable-header,
body .ui-datatable .ui-datatable-scrollable-footer {
  background-color: #f5f5f5;
}
body
  .ui-datatable
  .ui-datatable-scrollable-header
  .ui-datatable-scrollable-header-box
  td,
body
  .ui-datatable
  .ui-datatable-scrollable-footer
  .ui-datatable-scrollable-header-box
  td {
  color: #373a3c;
}
body .ui-datatable .ui-datatable-data tr.ui-rowgroup-header {
  font-weight: bold;
}
body .ui-datatable .ui-rowgroup-header {
  color: #2d353c;
}

body .ui-datatable .ui-rowgroup-header a {
  color: #2d353c;
}

body .ui-datagrid .ui-datagrid-header {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
}
body .ui-datagrid .ui-paginator {
  padding: 0;
}
body .ui-datalist .ui-datalist-header {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
}
body .ui-datalist .ui-paginator {
  padding: 0;
}
body .ui-datascroller .ui-datascroller-header {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
}
body .ui-picklist .ui-picklist-buttons button {
  font-size: 1.1em;
}
body .ui-picklist .ui-picklist-caption {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
}
body .ui-picklist .ui-picklist-filter-container {
  margin-bottom: 0.063em;
}
body .ui-picklist .ui-picklist-filter-container .ui-icon {
  top: 0.313em;
  right: 0.25em;
  color: #747a7f;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item {
  padding: 0.375em 0.75em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
body
  .ui-picklist
  .ui-picklist-list
  .ui-picklist-item:not(.ui-state-highlight):hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-orderlist .ui-orderlist-controls button {
  font-size: 1.1em;
}
body .ui-orderlist .ui-orderlist-caption {
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
  box-sizing: border-box;
  width: 12.5em;
}
body .ui-orderlist .ui-orderlist-list {
  box-sizing: border-box;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
  padding: 0.375em 0.75em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:last-child {
  border-bottom: none;
}
body
  .ui-orderlist
  .ui-orderlist-list
  .ui-orderlist-item:not(.ui-state-highlight):hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-orderlist.ui-grid-responsive .ui-grid-row .ui-orderlist-controls {
  margin-right: 0;
  padding-right: 0.5em;
}
body .ui-carousel {
  padding: 0;
}
body .ui-carousel .ui-carousel-header {
  margin: 0;
  border: 0 none;
  border-bottom: 1px solid #e6e6e6;
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-carousel .ui-carousel-header .ui-carousel-header-title {
  padding: 0;
}
body .ui-carousel .ui-carousel-footer {
  border: 0 none;
  border-top: 1px solid #e6e6e6;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0.75em 1.25em;
  background-color: #f5f5f5;
}
body .ui-carousel .ui-carousel-button {
  margin-top: 0.2em;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #767b7f;
}
body .ui-carousel .ui-carousel-button:hover {
  color: #2d353c;
}
body .ui-carousel .ui-carousel-button.ui-state-disabled:hover {
  color: #2d353c;
}
body .ui-carousel .ui-carousel-page-links {
  margin-top: 0.188em;
}
body .ui-carousel .ui-carousel-page-links .ui-carousel-page-link {
  color: #767b7f;
}
body .ui-carousel .ui-carousel-page-links .ui-carousel-page-link.fa-circle-o {
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
body
  .ui-carousel
  .ui-carousel-page-links
  .ui-carousel-page-link.fa-circle-o::before {
  content: "";
}
body
  .ui-carousel
  .ui-carousel-page-links
  .ui-carousel-page-link.fa-dot-circle-o {
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
body
  .ui-carousel
  .ui-carousel-page-links
  .ui-carousel-page-link.fa-dot-circle-o::before {
  content: "";
}
body
  .ui-carousel
  .ui-carousel-page-links
  .ui-carousel-page-link.ui-icon-radio-on {
  color: #0275d8;
  background-color: #767b7f;
}
body .ui-tree {
  box-sizing: border-box;
}
body .ui-tree .ui-treenode .ui-treenode-content .ui-tree-toggler {
  font-size: 1.1em;
  vertical-align: middle;
  display: inline-block;
  color: #747a7f;
  margin-top: 0.2em;
}
body .ui-tree .ui-treenode .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0.2em 0.375em 0 0;
  font-size: 1.1em;
  color: #747a7f;
}
body .ui-tree .ui-treenode .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0;
}
body
  .ui-tree.ui-tree-selectable
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body
  .ui-tree
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-tree .ui-tree-droppoint:hover {
  background-color: #0275d8;
}
body .ui-tree.ui-tree-horizontal .ui-treenode-content {
  background-color: #ffffff;
  border: 1px solid #c7c7c7;
}
body
  .ui-tree.ui-tree-horizontal
  .ui-treenode-content.ui-treenode-selectable
  .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
body
  .ui-tree.ui-tree-horizontal
  .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-highlight {
  background-color: #0275d8;
  color: #ffffff;
}
body
  .ui-tree.ui-tree-horizontal
  .ui-treenode-content.ui-state-highlight
  .ui-tree-toggler,
body
  .ui-tree.ui-tree-horizontal
  .ui-treenode-content.ui-state-highlight
  .ui-treenode-icon {
  color: #ffffff;
}
body .ui-tree.ui-tree-horizontal .ui-treenode-content .ui-chkbox .ui-icon {
  color: #0275d8;
}
body .ui-tree-draghelper {
  border: 1px solid #0275d8;
}
body .fc .fc-event {
  background-color: #025aa5;
  border-color: #025aa5;
}
body .fc .fc-slats td {
  background-color: #ffffff;
}
body .fc .fc-toolbar .fc-prev-button span {
  line-height: inherit;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
body .fc .fc-toolbar .fc-prev-button span::before {
  content: "";
}
body .fc .fc-toolbar .fc-prev-button .ui-icon {
  position: relative;
  top: 0.186em;
}
body .fc .fc-toolbar .fc-next-button span {
  line-height: inherit;
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
body .fc .fc-toolbar .fc-next-button span::before {
  content: "";
}
body .fc .fc-toolbar .fc-next-button .ui-icon {
  position: relative;
  top: 0.186em;
}

body .ui-tooltip .ui-tooltip-text {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .ui-tooltip.ui-state-error {
  border: 0 none;
}
body .ui-lightbox {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .ui-lightbox .ui-lightbox-caption {
  padding: 0.375em 0.75em;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
  color: #2d353c;
  margin: 0;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
  padding: 0;
  color: #2d353c;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
  color: #0275d8;
}
body .ui-lightbox .ui-lightbox-content-wrapper {
  overflow: hidden;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left {
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.5em;
  margin-left: 0.25em;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left::before {
  content: "";
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left span {
  display: none;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
  background: none !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.5em;
  margin-right: 0.25em;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right::before {
  content: "";
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right span {
  display: none;
}
body
  .ui-lightbox
  .ui-lightbox-content-wrapper
  .ui-lightbox-content.ui-lightbox-loading
  + a {
  display: none;
}
body .ui-breadcrumb {
  background-color: #ffffff;
  padding: 0.375em 0.75em;
}
body .ui-breadcrumb ul li {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
body .ui-breadcrumb ul li .ui-menuitem-link {
  color: #0275d8;
  margin: 0;
  position: relative;
  top: 0;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  font-size: 1em;
  margin: 0.25em 0.375em 0 0.375em;
  color: #767b7f;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron.fa-chevron-right::before {
  content: "/";
  font-size: 1.375em;
  font-weight: 700;
}
body .ui-breadcrumb ul li:first-child {
  font-size: 1.2em;
}
body .ui-breadcrumb ul li:first-child a.ui-icon-home {
  color: #767b7f;
  margin: 0;
}
body .ui-steps {
  position: relative;
}
body .ui-steps .ui-steps-item {
  background-color: transparent;
  text-align: center;
}
body .ui-steps .ui-steps-item.ui-state-disabled {
  opacity: 1;
  filter: alpha(opacity=100);
}
body .ui-steps .ui-steps-item .ui-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  display: inline-block;
  background-color: #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0.25em 0.563em;
  color: #747a7f;
  position: relative;
  width: 28px;
  margin-top: 0;
  font-size: 1em;
  top: 0.875em;
  margin-bottom: 0.75em;
  border: 1px solid #cccccc;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  display: block;
  margin-top: 0.375em;
  color: #747a7f;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background-color: #0275d8;
  color: #ffffff;
  border-color: #0275d8;
}
body .ui-steps .ui-steps-item:not(.ui-state-highlight) .ui-steps-number:hover {
  background-color: #e6e6e6;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 700;
  color: #373a3c;
}
body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
  display: block;
}
body .ui-steps::before {
  content: " ";
  border: 1px solid #0275d8;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}
body .ui-menu {
  padding: 0;
}
body .ui-menu.ui-shadow {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .ui-menu .ui-menu-parent .ui-submenu-icon {
  margin-top: 0.2em;
}
body .ui-menu .ui-menu-list .ui-widget-header {
  width: 100%;
  box-sizing: border-box;
  border: 0 none;
  background: #e6e6e6;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-menu .ui-menu-list .ui-widget-header h3 {
  padding: 0.375em 0.75em;
  font-weight: 400;
}
body .ui-menu .ui-menu-list .ui-widget-header .ui-icon {
  color: #767b7f;
}
body .ui-menu .ui-menu-list .ui-widget-header:hover {
  background-color: #e6e6e6;
}
body .ui-menu .ui-menu-list .ui-widget-header:hover .ui-icon {
  color: #2d353c;
}
body .ui-menu .ui-menu-list .ui-widget-header:first-child {
  margin-top: 0;
}
body .ui-menu .ui-menu-list .ui-menuitem {
  margin: 0;
  box-sizing: border-box;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link {
  padding: 0.5em 0.75em;
  width: 100%;
  box-sizing: border-box;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #373a3c;
}
body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link:hover,
body
  .ui-menu
  .ui-menu-list
  .ui-menuitem.ui-menuitem-active
  > .ui-menuitem-link {
  background-color: #0275d8;
  color: #ffffff;
}
body .ui-menu .ui-menu-list .ui-menu-child {
  padding: 0;
}
body .ui-menu .ui-menu-list .ui-menu-child.ui-shadow {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .ui-menu.ui-menubar {
  background-color: #ffffff;
  border-color: #e6e6e6;
}
body .ui-menu.ui-menubar > .ui-menu-list > .ui-menuitem {
  width: auto;
}
body .ui-menu.ui-menubar > .ui-menu-list > .ui-menuitem > .ui-menuitem-link {
  color: #2d353c;
}
body
  .ui-menu.ui-menubar
  > .ui-menu-list
  > .ui-menuitem
  > .ui-menuitem-link
  .ui-menuitem-icon {
  color: #767b7f;
}
body
  .ui-menu.ui-menubar
  > .ui-menu-list
  > .ui-menuitem
  > .ui-menuitem-link:hover {
  color: #ffffff;
}
body
  .ui-menu.ui-menubar
  > .ui-menu-list
  > .ui-menuitem
  > .ui-menuitem-link:hover
  .ui-menuitem-icon {
  color: #ffffff;
}
body .ui-menu.ui-menubar > .ui-menu-list > .ui-menuitem.ui-menubar-options {
  margin-right: 8px;
}
body .ui-menu.ui-megamenu .ui-menu-list > table > tbody > tr > td {
  padding: 0;
}
body .ui-menu.ui-megamenu.ui-megamenu-vertical > .ui-menu-list .ui-menuitem {
  width: 100%;
}
body .ui-tabmenu {
  border: 0 none;
}
body .ui-tabmenu .ui-tabmenu-nav {
  padding: 0;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  margin: 0;
  position: static;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  color: #2d353c;
  padding: 0.375em 0.75em;
}
body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem
  .ui-menuitem-link
  .ui-menuitem-icon,
body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem
  .ui-menuitem-link
  .ui-menuitem-text {
  vertical-align: middle;
  display: inline-block;
  float: none;
}
body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem
  .ui-menuitem-link
  .ui-menuitem-icon {
  color: #767b7f;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):hover {
  background-color: #e6e6e6;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:hover .ui-menuitem-icon {
  color: #2d353c;
}
body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem.ui-state-active
  .ui-menuitem-link {
  background-color: #0275d8;
  color: #ffffff;
}
body
  .ui-tabmenu
  .ui-tabmenu-nav
  .ui-tabmenuitem.ui-state-active
  .ui-menuitem-link
  .ui-menuitem-icon {
  color: #ffffff;
}
body .ui-menubutton .ui-button.ui-button-text-icon-left .ui-icon {
  margin-top: -0.625em;
}
body .ui-panelmenu .fa {
  position: static;
}
body .ui-panelmenu .ui-panelmenu-panel {
  border: 1px solid #c7c7c7;
  border-bottom: none;
}
body .ui-panelmenu .ui-panelmenu-panel:last-child {
  border-bottom: 1px solid #c7c7c7;
}
body .ui-panelmenu .ui-panelmenu-header {
  background-color: #ffffff;
  padding: 0;
  font-size: 1em;
  color: #2d353c;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .ui-panelmenu .ui-panelmenu-header .fa {
  color: #767b7f;
}
body .ui-panelmenu .ui-panelmenu-header a {
  padding: 0.5em 0.75em;
  color: #2d353c;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover {
  background-color: #e6e6e6;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover .fa {
  color: #2d353c;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active {
  background-color: #0275d8;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active a {
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active .fa {
  color: #ffffff;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link {
  padding: 0.375em 0.75em;
  color: #373a3c;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover {
  background-color: #f4f3f4;
  color: #373a3c;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link > span.fa {
  display: inline-block;
  vertical-align: middle;
  left: auto;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link .ui-menuitem-text {
  display: inline-block;
  vertical-align: middle;
}
body .ui-progressbar {
  border: 0 none;
  background-color: #e3e3e3;
}
body .ui-progressbar .ui-progressbar-value {
  border: 0 none;
  background: #299bfd;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-progressbar .ui-progressbar-label {
  margin-top: -0.063em;
}
body .ui-galleria .ui-galleria-nav-prev {
  font-size: 1em;
  color: #767b7f;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-galleria .ui-galleria-nav-prev:hover {
  color: #2d353c;
}
body .ui-galleria .ui-galleria-nav-next {
  font-size: 1em;
  color: #767b7f;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
body .ui-galleria .ui-galleria-nav-next:hover {
  color: #2d353c;
}

body .ui-slidemenu .ui-slidemenu-backward {
  border: 0 none;
  background-color: #f5f5f5;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

body .ui-inputgroup .ui-inputgroup-addon {
  border-color: rgba(0, 0, 0, 0.15);
  background-color: #eceeef;
  color: #55595c;
  padding: 0.5em 0.75em;
}

body .ui-inputgroup .ui-inputgroup-addon:first-child {
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}

body .ui-inputgroup .ui-inputgroup-addon:last-child {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

body .ui-inputgroup .ui-button:first-child {
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
.ui-inputgroup .ui-button:last-child {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.label-draft {
  background-color: #8c8f93 !important;
}
.label-initiated {
  background-color: #009dd9 !important;
}
.label-progress {
  background-color: #e5601f !important;
}
.label-completed {
  background-color: #6cb31e !important;
}
.label-pending {
  background-color: #faab18 !important;
}
.label-approved {
  background-color: #00b2bd !important;
}
.label-closed {
  background-color: #0066b2 !important;
}
.label-cancel {
  background-color: #e21836 !important;
}
