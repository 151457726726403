.outageCreation{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem 0;
    padding: 0 1rem;
    border-bottom: 1px solid #ccc;
}

.grid-container {
  display: grid;
  grid-template-columns: .5fr 20px 1fr 20px 1.5fr;
}

.sofa-quick-container{
  display: grid;
  grid-template-columns: 62.5% 31%;
  gap: 1.5%;
}

.sap-vendor-container {
  display: grid;
  grid-template-columns: 35% 58%;
  gap: 1.5%;
  grid-auto-rows: minmax(300px, auto); 
}

.outage-header-container {
  display: grid;
  grid-template-columns: 40% 54%;
  gap: 1%;
  grid-auto-rows: minmax(300px, auto); 
}

.header-card{
  width : 95% !important;
}

.outage-search-buttons{
  padding : 5px;
}

.quick-links .p-card{
  height: 49.35vh; 
}
.table-container .p-selectable-row{
  height: 66px
}

//--------------------------------------------------------------------------------
@media screen and (max-width: 768px) {
  .outageCreation{
      flex-direction: column;
      justify-content: center;
  }
}
