::ng-deep .p-menubar{
    padding: 0;
    flex-direction: column;
    background-color: #0b2d71;
    border: none;
    border-radius: 0;
    .p-menubar-start{
      padding-top: 16px;
      display: block;
      width: 100%;
      background-color: #f8f8f8;
      min-height: 120px;
      h2{
        color: #009dd9;
      }
      .logo{
        position: absolute;
        left: 0;
        right: 0;
      }
       .p-dropdown {
        width: fit-content;
       }
    }
    .user {
      display: flex;
      padding: 20px;
      color: #0b2d71;
      font-family: "GothamBook";
      img {
        border-radius: 50%;
        height: 35px;
        width: 35px;
        margin-left: 15px;
      }
      span {
        display: grid;
        font-size: 16px;
        font-weight: bolder;
        span {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
     .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text{
      color: white;
     }
     p-menubarsub{
      height: 45px;
      align-items: center;
      justify-content: center;
      // display: flex;
      ul{
        &.p-menubar-root-list{
          height: inherit;
        }
        li{
          margin-right: 15px;
          margin-left: 15px;
          // display: flex;
          .p-menuitem-text{
            font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
          }
          a.p-menuitem-link{
              &:focus{
                box-shadow: none !important;
              }
          }
        }
      }
     }
  }