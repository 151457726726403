// .p-datatable {
// }
.outageCreationTable .original{
    width: 60vw;
    padding-right: 5vw;
}

.outageCreationTable .selected{
    width: 30vw;
}

.outageCreationTable h3{
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .outageCreationTable {
        flex-direction: column;
    }
    .outageCreationTable .original{
        width: 100%;
        padding-right: 0;
    }

    .outageCreationTable .selected{
        width: 100%;
    }
}


