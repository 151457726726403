nav.nav-column .module {
    padding: 0;
}

nav.nav-column h3 {
    background-color: #0B2D71;
    padding: 20px 10px;
    color: #fff;
    font-size: 18px;
    margin-top: 0;
}
nav.nav-column h3:hover,
nav.nav-column h3:focus,
nav.nav-column h3:active {
    background-color: #0066b2;
}
nav.nav-column ul {
    padding: 0 15px;
}
nav.nav-column ul.nav-level-3 {
    padding-bottom: 15px;
}
nav.nav-column ul.nav-level-4 {
    padding-left: 15px;
}
nav.nav-column ul.nav-level-4 li {
    padding: 5px 0;
}
nav.nav-column ul.nav-level-4 li:first-child {
    padding-top: 10px;
}
nav.nav-column ul.nav-level-4 li:last-child {
    padding-bottom: 0;
}
nav.nav-column ul li {    
    font-size: 16px;
    list-style-type: none;
    padding: 10px 0;
}
nav.nav-column ul li.panel-group {
    margin-bottom:0;
}
nav.nav-column ul li.panel-group .glyphicon-minus {
    font-size: 1.75em;
    font-weight: 900;
    color: #8C8F93;
    top:-8px
}
nav.nav-column ul li.panel-group .collapsed .glyphicon-minus:before {
    content: "\2b";
}
nav.nav-column ul li a {    
    color: #000;
}
nav.nav-column .current-selected-node > a,
nav.nav-column .current-selected-node > div > a,
nav.nav-column li.has-selected-descendent > div > a {
    font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
