.bg-odd {
  background-color: $contrast-color-2;
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-start {
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.d-center-on-left{
  display: flex; 
  justify-content: flex-start; 
  align-items: center;
}
.w-100 {
  width: 100%;
}
.w-25 {
  width: 25vw;
}
.w-5px{
  width: 5px;
}
.flex-wrap {
  flex-wrap: wrap;
}

.highlight {
  color: #0066b2 !important;
}
.light-grey {
  color: grey;
}

.subtitle {
  font-size: 18px !important;
}

.detailed-description {
  font-size: 12px;
  font-style: italic;
  color: rgb(150, 150, 150);
}

.right-padding {
  padding-right: 10px;
}
.top-padding{
  padding-top: 1%;
}
.card-component{
  margin-top: 3rem; 
  padding: 20px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card-component-white{
  flex: 0 1 auto;
  position: relative;
  padding: 0 13px 0 15px;
  background-color: #fff;
  margin-top: 3rem; 
  margin-bottom: 3rem;
  border: 2px solid #dbdcdd;
    border-top-style: none;
    border-left-style: none;
    border-radius: 0;
}