
.p-picklist .p-picklist-buttons {
padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
background: #f8f9fa;
color: #495057;
border: 1px solid #dee2e6;
padding: 1rem;
border-bottom: 0 none;
border-top-right-radius: 3px;
border-top-left-radius: 3px;
}
.p-picklist .p-picklist-header .p-picklist-title {
font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
padding: 1rem;
background: #ffffff;
border: 1px solid #dee2e6;
border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
padding-right: 1.5rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
right: 0.5rem;
color: #6c757d;
}
.p-picklist .p-picklist-list {
border: 1px solid #dee2e6;
background: #ffffff;
color: #495057;
padding: 0.5rem 0;
border-bottom-right-radius: 3px;
border-bottom-left-radius: 3px;
}
.p-picklist .p-picklist-list .p-picklist-item {
padding: 0.5rem 1rem;
margin: 0;
border: 0 none;
color: #495057;
background: transparent;
transition: box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
outline: 0 none;
outline-offset: 0;
box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
color: #495057;
background: #E3F2FD;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
padding: 0.5rem 1rem;
color: #495057;
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
background: #e9ecef;
color: #495057;
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
background: #e9ecef;
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
background: #e9ecef;
}

.p-picklist-item.cdk-drag-preview {
padding: 0.5rem 1rem;
box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
border: 0 none;
color: #495057;
background: #ffffff;
margin: 0;
}
