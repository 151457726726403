$success-border-color: #d0e9c6;
$success-background-color: #dff0d8;
$success-color: #3c763d;

$info-background-color: #d9edf7;
$info-border-color: #bcdff1;
$info-color: #31708f;

$warn-background-color: #fcf8e3;
$warn-border-color: #faf2cc;
$warn-color: #8a6d3b;

$error-background-color: #f2dede;
$error-border-color: #ebcccc;
$error-color: #a94442;

$contrast-color-1: #fbfbfb;
$contrast-color-2: #f3f4f8;

$lightgray: #efefef;
$darkgray: darkgray;
$darkblue: #0b2d71;
$lightblue: #009dd9;

$light-border: 1px solid $lightgray;
$dark-border: 1px solid $darkgray;
