.p-dialog {
  &-title {
    font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 26px !important;
    padding: 5px !important;
    font-weight: 100 !important;
    border-bottom: 0px !important;
    color: #0b2d71;
    letter-spacing: 0;
  }
  .subtitle {
    font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-weight: 100 !important;
    font-size: 18px !important;
  }
}
