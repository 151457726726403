body {
  .btn-outline {
    background-color: transparent;
    color: #0275d8;
    border: 0.1em #0275d8 solid;
    font-weight: 600;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: transparent;
      color: #204d74 !important;
      border: 0.1em #204d74 solid !important;
      outline: none !important;
    }
  }
  .btn-light {
    background-color: #f8fdff;
  }
  .ui-button {
    color: #ffffff;
    background-color: #0275d8;
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 1em;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;

    &.ui-button-standalone {
      margin: 0 10px;

      & + .ui-button-standalone {
        margin-left: 5px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:focus {
      outline: 0 none;
      background-color: #0267bf;
    }

    &:enabled {
      &:hover {
        outline: 0 none;
        background-color: #0267bf;
      }

      &:active {
        background-color: #025aa5;
        -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
    }
  }

  .ui-button,
  .ui-button.ui-button-text-only {
    .ui-button-text {
      padding: 0;
    }
  }
}
