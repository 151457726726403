.p-overlaypanel {
  &.no-shadow {
    background-color: #f7f7f8;
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
      3px 1px 6px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 3px 1px 6px 5px rgba(0, 0, 0, 0);
    &:after,
    &:before {
      content: none;
    }
  }
  &.full-width {
    width: 98vw;
  }
}
